import {NgModule} from '@angular/core';
import {DialogsService} from '../dialogs/dialogs.service';
import {UserCardDialogComponent} from '../dialogs/user-card-dialog/user-card-dialog.component';
import {BadgePageModule} from '../badge-page/badge-page.module';
import {SharedModule} from '../shared/shared.module';
import {CommonModule} from '@angular/common';
import {MomentModule} from 'ngx-moment';
import {CastDialogComponent} from '../dialogs/cast-dialog/cast-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  imports: [
    CommonModule,
    MomentModule,
    SharedModule,
    BadgePageModule,
    MatDialogModule,
    MatButtonModule
  ],
  declarations: [UserCardDialogComponent, CastDialogComponent],
  providers: [DialogsService]
})
export class DialogsModule {
}
