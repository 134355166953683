import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfilePageOverviewComponent } from './profile-page-overview/profile-page-overview.component';
import { EditDetailsCardComponent } from './edit-details-card/edit-details-card.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
// import { MatNativeDateModule } from '@angular/material/datepicker';

import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { LinkedAccountsCardComponent } from './linked-accounts-card/linked-accounts-card.component';
import { LinkAccountsDialogComponent } from './link-accounts-dialog/link-accounts-dialog.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    // MatNativeDateModule,
    MatButtonModule,
    MatSelectModule,
    MatDialogModule,
    SharedModule,
    MatSnackBarModule,
  ],
  declarations: [ProfilePageOverviewComponent, EditDetailsCardComponent, LinkedAccountsCardComponent, LinkAccountsDialogComponent],
  exports: [ProfilePageOverviewComponent],
  entryComponents: [LinkAccountsDialogComponent],
})
export class ProfilePageModule {}
