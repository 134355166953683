import {Component} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {Game, Player} from '@move4mobile/m4m-common-types/lib/code-heroes/models';
import {Util} from '../../shared/util';

@Component({
  selector: 'app-leaderboard-individual-card',
  templateUrl: './leaderboard-individual-card.component.html',
  styleUrls: ['./leaderboard-individual-card.component.scss']
})
export class LeaderboardIndividualCardComponent {

  users: Player[];
  loading = true;
  initLoading = true;
  currentGame: string;

  constructor(private db: AngularFirestore) {
    this.getCurrentGame();
    this.initLoading = false;
  }

  async getUsers(gameKey) {
    this.db.collection<Player>('games/' + gameKey + '/players').snapshotChanges().map(data => {
      return data.map(u => {
        const result = u.payload.doc.data();
        const id = u.payload.doc.id;
        return {id, ...result};
      });
    }).subscribe((users: Player[]) => {
      this.users = Util.sortByScore(users);
    });
  }

  getCurrentGame() {
    this.db.collection<Game>('games', ref => ref.where('status', '==', 'IN_PROGRESS')).valueChanges().subscribe(
      (data: Game[]) => {
        if (data[0] !== undefined) {
          this.currentGame = data[0].id;
          this.getUsers(this.currentGame);
          this.loading = false;
        } else {
          this.loading = true;
        }
      });
  }
}
