import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CastOverviewComponent} from './cast-overview/cast-overview.component';
import {CastLeaderboardItemComponent} from './cast-leaderboard-item/cast-leaderboard-item.component';
import {CastActivityItemComponent} from './cast-activity-item/cast-activity-item.component';
import {MomentModule} from 'ngx-moment';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';


@NgModule({
  imports: [
    CommonModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    MomentModule,
    MatProgressBarModule
  ],
  declarations: [CastOverviewComponent, CastLeaderboardItemComponent, CastActivityItemComponent]
})
export class CastModule {
}
