import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BadgesProgressCardComponent} from './badges-progress-card/badges-progress-card.component';
import {BadgesEarnedCardComponent} from './badges-earned-card/badges-earned-card.component';
import {BadgePageOverviewComponent} from './badge-page-overview/badge-page-overview.component';
import {SharedModule} from '../shared/shared.module';
import {BadgeProgressItemComponent} from './badge-progress-item/badge-progress-item.component';
import {BadgeEarnedItemComponent} from './badge-earned-item/badge-earned-item.component';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
    SharedModule,
    MatProgressBarModule
  ],
  exports: [BadgeEarnedItemComponent],
  declarations: [
    BadgesProgressCardComponent,
    BadgesEarnedCardComponent,
    BadgePageOverviewComponent,
    BadgeProgressItemComponent,
    BadgeEarnedItemComponent]
})
export class BadgePageModule {
}
