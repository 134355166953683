import {Component, OnInit, Input} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {Player, Team} from '@move4mobile/m4m-common-types/lib/code-heroes/models';
import {Util} from '../../shared/util';

@Component({
  selector: 'app-current-team-card',
  templateUrl: './current-team-card.component.html',
  styleUrls: ['./current-team-card.component.scss']
})
export class CurrentTeamCardComponent implements OnInit {
  @Input() currentTeam: Team;
  @Input() currentGame: string;

  memberCount: number;
  members: Player[];

  constructor(private db: AngularFirestore) {
  }

  ngOnInit() {
    this.getMembers();
  }

  getMembers() {
    this.db.collection<Player>('games/' + this.currentGame + '/players', ref =>
      ref.where('teamID', '==', this.currentTeam.id)).valueChanges().subscribe(
      (data: Player[]) => {
        this.members = Util.sortByScore(data);
        this.memberCount = data.length;
      });
  }
}
