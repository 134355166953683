import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GameOverviewComponent} from './game-overview/game-overview.component';
import {SharedModule} from '../shared/shared.module';
import {MatCardModule} from '@angular/material/card';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    MatCardModule
  ],
  declarations: [GameOverviewComponent]
})
export class GamePageModule {
}
