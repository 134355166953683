import {Component, OnInit, Input} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {AnalyzeService} from '../../shared/analyze.service';

@Component({
  selector: 'app-cast-activity-item',
  templateUrl: './cast-activity-item.component.html',
  styleUrls: ['./cast-activity-item.component.scss']
})
export class CastActivityItemComponent implements OnInit {

  @Input() activity: any;
  user: any;
  activityText: string;
  activityDescription: string;
  chips: any;
  typeImg: any;

  constructor(private afs: AngularFirestore, private analyzeService: AnalyzeService) {
  }

  ngOnInit() {
    if (this.activity.user) {
      this.afs.collection('users').doc(this.activity.user).valueChanges().subscribe(data => {
        this.user = data;
      });
    } else {
      this.user = {
        displayName: this.activity.eventData.authorName,
        photoUrl: '../../../assets/m4m.jpg'
      };
    }
    this.analyzeService.analyzeActivity(this.activity);
    this.typeImg = this.analyzeService.getTypeImg();
    this.activityText = this.analyzeService.getActivityText();
    this.activityDescription = this.analyzeService.getActivityDescription();
    this.chips = this.analyzeService.getChips();
  }
}
