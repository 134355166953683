import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../auth/auth.service';
import {AngularFirestore} from '@angular/fire/firestore';
import {IUser} from '@move4mobile/m4m-common-types/lib/code-heroes/models';

@Component({
  selector: 'app-profile-page-overview',
  templateUrl: './profile-page-overview.component.html',
  styleUrls: ['./profile-page-overview.component.scss']
})
export class ProfilePageOverviewComponent implements OnInit {
  user: any;
  currentUser: IUser;
  auth: AuthService;
  accounts = {
    github: [],
    google: []
  };

  constructor(auth: AuthService, private afs: AngularFirestore) {
    this.auth = auth;
  }

  ngOnInit() {
    this.user = this.auth.userData;
    this.getCurrentUser(this.user.email).subscribe(data => {
      this.currentUser = data;
    });
    this.getLinkedAccounts(this.user.email);
  }

  getCurrentUser(key) {
    return this.afs.doc<IUser>(`users/${key}`).valueChanges();
  }

  getLinkedAccounts(key) {
    this.afs.collection(`users/${key}/accounts`, ref => ref.where('type', '==', 'google')).valueChanges().subscribe(data => {
      for (const item of data) {
        this.accounts.google.push(item);
      }
    });
    this.afs.collection(`users/${key}/accounts`, ref => ref.where('type', '==', 'github')).valueChanges().subscribe(data => {
      for (const item of data) {
        this.accounts.github.push(item);
      }
    });
  }

  handleEdit(event) {
    const userRef = this.afs.doc(`users/${this.currentUser.email}`);
    userRef.update({
      displayName: event.displayName,
      gameName: event.gameName,
    });
    if (event.role !== undefined) {
      userRef.update({
        role: event.role
      });
    }
    if (event.dateOfBirth !== undefined) {
      userRef.update({
        dateOfBirth: event.dateOfBirth
      });
    }
  }
}
