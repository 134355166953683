import {Component, OnInit, Input} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';

@Component({
  selector: 'app-cast-leaderboard-item',
  templateUrl: './cast-leaderboard-item.component.html',
  styleUrls: ['./cast-leaderboard-item.component.scss']
})

export class CastLeaderboardItemComponent implements OnInit {
  @Input() team: any;
  @Input() rank: number;
  @Input() currentGame: string;

  color: string;
  last: Boolean;
  mvp: any;
  members: any;

  constructor(private db: AngularFirestore) {
  }

  static sortByScore(array) {
    array.sort((a: any, b: any) => {
      if (a.score > b.score) {
        return -100;
      } else if (a.score < b.score) {
        return 100;
      } else {
        return 0;
      }
    });
    return array;
  }

  ngOnInit() {
    if (this.team) {
      this.getTopPlayer(this.team.id);
      this.getMembers();
    }

    switch (this.rank) {
      case 1:
        this.color = '#FFD700';
        break;
      case 2:
        this.color = '#919191';
        break;
      case 3:
        this.color = '#664426';
        break;
      case 4:
        this.color = '#434343';
        this.last = true;
        break;
    }
  }

  getTopPlayer(teamId) {
    const playersRef = this.db.collection('games').doc(this.currentGame).collection('players', ref => ref.where('teamID', '==', teamId));
    playersRef.valueChanges().subscribe(data => {
      const players = CastLeaderboardItemComponent.sortByScore(data);
      this.mvp = players[0];
    });
  }

  getMembers() {
    this.db.collection('games').doc(this.currentGame)
      .collection('players', ref => ref.where('teamID', '==', this.team.id)).valueChanges().subscribe(data => {
      this.members = CastLeaderboardItemComponent.sortByScore(data);
    });
  }
}
