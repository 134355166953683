<mat-card *ngIf="googleAccounts.length > 0" class="card-fade-in">
  <div class="header row">
    <div class="cardTitle columns small-12">Linked accounts</div>
    <div class="cardDescription columns"> Link accounts to start earning points for your activities.</div>
  </div>
  <div class="accounts">
    <div *ngIf="googleAccounts.length > 0" class="google row align-middle">
      <div class="googleAvatar"></div>
      <div class="accountsList columns">
        <div class="accountName" *ngFor="let account of googleAccounts">{{account.email}}</div>
      </div>
    </div>
    <div *ngIf="githubAccounts.length > 0" class="git row align-middle">
      <div class="gitAvatar"></div>
      <div class="accountsList columns">
        <div class="accountName" *ngFor="let account of githubAccounts">{{account.username}}</div>
      </div>
    </div>
  </div>
  <div class="buttonContainer">
    <button mat-raised-button class="linkButton" (click)="openDialog()">Link</button>
  </div>
</mat-card>
