<mat-card class="card-fade-in">
  <div class="header row">
    <div class="cardTitle columns small-12">Progress</div>
    <div class="cardDescription columns">A list of all the badges you are currently progressing towards
    </div>
  </div>
  <div *ngIf="!loading" class="fade-in">
    <div *ngFor="let badge of badges" class="progressContainer fade-in">
      <app-badge-progress-item [badge]="badge"></app-badge-progress-item>
    </div>
  </div>
</mat-card>
