import {Component, OnInit, Input} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {DialogsService} from '../../dialogs/dialogs.service';
import {AnalyzeService} from '../../shared/analyze.service';
import {IActivity, IUser} from '@move4mobile/m4m-common-types/lib/code-heroes/models';

@Component({
  selector: 'app-activity-feed-item',
  templateUrl: './activity-feed-item.component.html',
  styleUrls: ['./activity-feed-item.component.scss']
})
export class ActivityFeedItemComponent implements OnInit {

  @Input() activity: IActivity;
  user: IUser;
  chips: Object[];
  activityText: string;
  activityDescription: string;
  typeImg: string;

  constructor(private afs: AngularFirestore, private dialog: DialogsService, private analyzeService: AnalyzeService) {
  }

  ngOnInit() {
    if (this.activity.user) {
      const user = this.afs.collection<IUser>('users').doc(this.activity.user).valueChanges();
      user.subscribe(
        (data: IUser) => {
          this.user = data;
        }
      );
    } else {
      // this.user = {
      //   displayName: this.activity.eventData.authorName,
      //   photoUrl: '../../../assets/m4m.jpg'
      // };
      console.log('INVALID USER');
    }
    this.analyzeService.analyzeActivity(this.activity);
    this.typeImg = this.analyzeService.getTypeImg();
    this.activityText = this.analyzeService.getActivityText();
    this.activityDescription = this.analyzeService.getActivityDescription();
    this.chips = this.analyzeService.getChips();
  }

  openUserDialog() {
    this.dialog.openDialog('user', this.user);
  }
}
