import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-leaderboard-overview',
  templateUrl: './leaderboard-overview.component.html',
  styleUrls: ['./leaderboard-overview.component.scss']
})
export class LeaderboardOverviewComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
