import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { environment } from '../environments/environment';

import { RouterModule, Routes } from '@angular/router';
// Component imports
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Angularfire2 + firebase imports
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFirestoreModule } from '@angular/fire/firestore';

import { LeaderboardOverviewComponent } from './leaderboards/leaderboard-overview/leaderboard-overview.component';
import { ActivityFeedOverviewComponent } from './activity-feed/activity-feed-overview/activity-feed-overview.component';
import { LeaderboardsModule } from './leaderboards/leaderboards.module';
import { ActivityFeedModule } from './activity-feed/activity-feed.module';
import { AuthModule } from './auth/auth.module';
import { LoginComponent } from './login-page/login/login.component';
import { loginModule } from './login-page/login.module';
import { AuthGuard } from './auth/auth.guard';
import { ProfilePageOverviewComponent } from './profile-page/profile-page-overview/profile-page-overview.component';
import { BadgePageOverviewComponent } from './badge-page/badge-page-overview/badge-page-overview.component';
import { CastOverviewComponent } from './cast-page/cast-overview/cast-overview.component';
import { TeamOverviewComponent } from './team-page/team-overview/team-overview.component';
import { ProfilePageModule } from './profile-page/profile-page.module';
import { CastModule } from './cast-page/cast.module';
import { BadgePageModule } from './badge-page/badge-page.module';
import { TeamPageModule } from './team-page/team-page.module';
import { GameOverviewComponent } from './game-page/game-overview/game-overview.component';
import { GamePageModule } from './game-page/game-page.module';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatProgressBarModule } from '@angular/material/progress-bar';

const routes: Routes = [
  { path: '', redirectTo: 'leaderboards', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'cast', component: CastOverviewComponent, canActivate: [AuthGuard] },
  { path: 'leaderboards', component: LeaderboardOverviewComponent, canActivate: [AuthGuard] },
  { path: 'activity', component: ActivityFeedOverviewComponent, canActivate: [AuthGuard] },
  { path: 'profile', component: ProfilePageOverviewComponent, canActivate: [AuthGuard] },
  { path: 'badges', component: BadgePageOverviewComponent, canActivate: [AuthGuard] },
  { path: 'teams', component: TeamOverviewComponent, canActivate: [AuthGuard] },
  { path: 'games', component: GameOverviewComponent, canActivate: [AuthGuard] },
  { path: '**', redirectTo: 'leaderboards' }
];

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    LeaderboardsModule,
    BrowserAnimationsModule,
    SharedModule,
    ActivityFeedModule,
    ProfilePageModule,
    CastModule,
    BadgePageModule,
    RouterModule.forRoot(routes, { useHash: false }),
    AngularFireModule.initializeApp(environment.firebase, 'code-heroes'),
    AngularFirestoreModule,
    AngularFireDatabaseModule,
    AuthModule,
    loginModule,
    TeamPageModule,
    GamePageModule,
    MatToolbarModule,
    MatProgressBarModule
  ],
  providers: [AngularFireAuth],
  bootstrap: [AppComponent]
})
export class AppModule { }
