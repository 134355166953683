<div class="row align-middle team">
  <div class="teamImg"
       [ngStyle]="{'border': '3px solid' + color, 'background-image': 'url('+ team.avatar + ')' }"></div>
  <div class="column align-left">
    <div class="row">
      <div class="columns name"> {{team.name}} </div>
    </div>
    <div *ngIf="members" class="row">
      <div *ngFor="let member of members | slice:0:6" class="columns members">
        <div class="memberImg"
             [ngStyle]="{'border': '1px solid' + color, 'background-image': 'url('+ member.avatar + ')' }"></div>
      </div>
      <div *ngIf="members?.length > 5" class="more">
        +{{members.length - 6}}
      </div>
    </div>
  </div>
  <div class="columns small-2 score">{{team.score}}</div>
</div>
