<mat-card *ngIf="user" class="card-fade-in">
  <div class="header row">
    <div class="cardTitle columns small-12">Account Details</div>
    <div class="cardDescription columns">Edit some personal info to personalize your profile.
    </div>
  </div>
  <form (ngSubmit)="onSubmit()" class="form">
    <div row align-left>
      <div columns small-2 *ngIf="user" class="profileImg"
           [ngStyle]="{'background-image': 'url('+ user.photoUrl + ')' }"></div>
      <mat-form-field class="name" columns>
        <input [(ngModel)]="formModel.gameName" name="displayName" matInput placeholder="Display Name"
               [formControl]="displayNameFormControl">
        <mat-error *ngIf="displayNameFormControl.hasError('required')">
          Display Name is
          <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>

    <mat-form-field>
      <mat-select [(ngModel)]="formModel.role" name="role" placeholder="Current role">
        <mat-option *ngFor="let role of roles" [value]="role.value"> {{role.viewValue}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <input [(ngModel)]="formModel.dateOfBirth" name="dateOfBirth" matInput [matDatepicker]="picker"
             placeholder="Choose a date of birth">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <div>
      <button class="save-button" mat-raised-button type="submit">Save</button>
    </div>
  </form>
</mat-card>
