<mat-card class="card-fade-in">
  <div class="header row">
    <div class="cardTitle columns small-12">Earned</div>
    <div class="cardDescription columns">A list of all the badges you obtained so far
    </div>
  </div>
  <div *ngIf="!loading" class="fade-in">
    <div *ngFor="let badge of badges" class="earnedContainer fade-in">
      <app-badge-earned-item [badge]="badge"></app-badge-earned-item>
    </div>
  </div>
</mat-card>