import {Component, OnInit, Input} from '@angular/core';
import {Player} from '@move4mobile/m4m-common-types/lib/code-heroes/models';

@Component({
  selector: 'app-team-member-item',
  templateUrl: './team-member-item.component.html',
  styleUrls: ['./team-member-item.component.scss']
})
export class TeamMemberItemComponent implements OnInit {
  // TO DO IMPLEMENT PLAYER MODEL AGAIN FOR member!, mist field: "score".
  @Input() member: Player;

  constructor() {
  }

  ngOnInit() {
  }

}
