import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TeamOverviewComponent} from './team-overview/team-overview.component';
import {SharedModule} from '../shared/shared.module';
import {CurrentTeamCardComponent} from './current-team-card/current-team-card.component';
import {MatCardModule} from '@angular/material/card';
import {TeamMemberItemComponent} from './team-member-item/team-member-item.component';
import {AllTeamsCardComponent} from './all-teams-card/all-teams-card.component';
import {TeamItemComponent} from './team-item/team-item.component';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    MatCardModule
  ],
  declarations: [TeamOverviewComponent, CurrentTeamCardComponent, TeamMemberItemComponent, AllTeamsCardComponent, TeamItemComponent]
})
export class TeamPageModule {

}
