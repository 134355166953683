<div *ngIf="user && activity" class="root">
  <div class="row align-middle fade-in">
    <div class="profileImage" [ngStyle]="{'background-image': 'url('+ user.avatar +')'}"></div>
    <div class="column">
      <div class="row">
        <div class="userName">{{user.displayName}}</div>
      </div>
      <div *ngIf="team" class="row">
        <div class="userTeamName">{{team.name}}</div>
      </div>
    </div>
    <div *ngIf="data.user.score" class="row align-right userScore">
      {{user.score}}
    </div>
  </div>

  <hr>

  <div *ngIf="activity && !loading">
    <div class="activity fade-in">
      <div class="activityTitle">Most recent activity:</div>
      <div class="row align-middle">
        <div class="profileImage" [ngStyle]="{'background-image':  'url('+ typeImg +')'}"></div>
        <div class="column">
          <div class="row">
            <div class="activityText">{{ capitalizeFirstLetter(activityText) }} {{activity.timestamp | amTimeAgo}}</div>
          </div>
          <div *ngIf="activity.repo" class="row activityRepo">
            @{{activity.repo}}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="badge">

    <hr>

    <div class="badge fade-in">
      <div class="activityTitle">Highest earned badge:</div>
      <div class="badge-earned  row align-middle">
        <app-badge-earned-item [badge]="badge"></app-badge-earned-item>
      </div>
    </div>
  </div>

</div>
