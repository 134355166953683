import {Component, Input} from '@angular/core';
import {Team} from '@move4mobile/m4m-common-types/lib/code-heroes/models';

@Component({
  selector: 'app-all-teams-card',
  templateUrl: './all-teams-card.component.html',
  styleUrls: ['./all-teams-card.component.scss']
})
export class AllTeamsCardComponent {
  @Input() teams: Team[];
  @Input() currentGame: string;

  constructor() {
  }
}
