<mat-card class="card-fade-in">
  <div *ngIf="loading">
    <mat-card-title>Top coders this game</mat-card-title>
    <mat-card-content class="fade-in-item">
      <mat-progress-bar mode="indeterminate">
      </mat-progress-bar>
      <app-leaderboard-top-three></app-leaderboard-top-three>
      <div class="hrSkeleton"></div>
      <div class="regularCoders">
        <mat-list>
          <app-leaderboard-item></app-leaderboard-item>
        </mat-list>
      </div>
    </mat-card-content>
  </div>
  <div *ngIf="!loading">
    <mat-card-title>Top coders this game</mat-card-title>
    <mat-card-content class="fade-in-item">
      <div *ngIf="loading" class="emptyState">
        <mat-progress-bar mode="indeterminate">
        </mat-progress-bar>
      </div>
      <div *ngIf="users && !loading" class="fade-in-item">
        <app-leaderboard-top-three [users]="users"></app-leaderboard-top-three>
        <hr>
      </div>
      <div *ngIf="!loading" class="regularCoders">
        <mat-list *ngFor="let user of users | slice:3" class="itemContainer fade-in-item">
          <app-leaderboard-item [user]="user"></app-leaderboard-item>
        </mat-list>
      </div>
    </mat-card-content>
  </div>
</mat-card>