<div *ngIf="rank && badge">
  <div class="row align-middle">
    <div class="badgeImg"
         [ngStyle]="{'background-image': 'url(../../../assets/badges/empty_'+badge.avatar_ref+'.png)'}"></div>
    <div class="column">
      <div class="row">
        <div class="badgeName columns">{{rank.name}} {{badge.badge_name}}</div>
        <div class="badgeProgress columns small-4">{{badge.event_occ}} / {{rank.needed_occ}}</div>
      </div>
      <div class="row badgeDescription">
        <div class="columns">{{badge.description}}</div>
      </div>
      <div class="row progressBar">
        <div class="columns" [ngClass]="color">
          <mat-progress-bar mode="determinate" value="{{(badge.event_occ / rank.needed_occ) * 100}}"></mat-progress-bar>
        </div>
      </div>
    </div>
  </div>
</div>
