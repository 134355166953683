import {Component, OnInit, Input, OnChanges} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {LinkAccountsDialogComponent} from '../link-accounts-dialog/link-accounts-dialog.component';

@Component({
  selector: 'app-linked-accounts-card',
  templateUrl: './linked-accounts-card.component.html',
  styleUrls: ['./linked-accounts-card.component.scss']
})
export class LinkedAccountsCardComponent implements OnInit {

  @Input() accounts: any;
  githubAccounts;
  googleAccounts;
  loading = true;

  constructor(public dialog: MatDialog) {

  }

  ngOnInit() {

  }

  openDialog(): void {
    const dialogRef = this.dialog.open(LinkAccountsDialogComponent);
  }

  ngOnChanges(changes) {
    if (changes.accounts) {
      this.githubAccounts = changes.accounts.currentValue.github;
      this.googleAccounts = changes.accounts.currentValue.google;
    }
  }
}
