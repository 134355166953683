import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {AuthService} from '../../auth/auth.service';
import {CastService} from './cast.service';
import {DialogsService} from '../../dialogs/dialogs.service';

interface NavigationRoutes {
  name: string;
  url: string;
}

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {
  public color: {};
  public user;
  profilePage = false;
  casting: boolean;

  public navigationRoutes: Array<NavigationRoutes>;
  selectedIndex: number;

  constructor(public router: Router, public auth: AuthService, private castService: CastService, public dialogsService: DialogsService) {

    this.castService.getSenderStatus().subscribe(
      (data) => {
        this.casting = data;
      }
    );

    this.navigationRoutes = [
      {
        name: 'Leaderboards',
        url: 'leaderboards'
      },
      {
        name: 'Activity',
        url: 'activity'
      },
      {
        name: 'Badges',
        url: 'badges'
      },
      {
        name: 'Teams',
        url: 'teams'
      },
      {
        name: 'Games',
        url: 'games'
      }
    ];

    this.user = this.auth.user;

    const currentRoute = window.location.pathname.split('/')[1];
    switch (currentRoute) {
      case 'leaderboards':
        this.selectedIndex = 0;
        this.color = {
          'selected': true,
          'deselected': false
        };
        break;
      case 'activity':
        this.selectedIndex = 1;
        this.color = {
          'selected': true,
          'deselected': false
        };
        break;
      case 'profile':
        this.selectedIndex = null;
        this.color = {
          'selected': false,
          'deselected': true
        };
        this.profilePage = true;
        break;
      case 'badges':
        this.selectedIndex = 2;
        this.color = {
          'selected': true,
          'deselected': false
        };
        break;
      case 'teams':
        this.selectedIndex = 3;
        this.color = {
          'selected': true,
          'deselected': false
        };
        break;
      case 'games':
        this.selectedIndex = 4;
        this.color = {
          'selected': true,
          'deselected': false
        };
        break;
    }
  }

  navigate(event: MatTabChangeEvent) {
    this.router.navigateByUrl(event.tab.textLabel.toLowerCase());
    this.profilePage = false;
  }

  cast() {
    this.castService.discoverCastDevices();
  }

  stopCast() {
    this.dialogsService.openCastDialog();
  }

  navToProfile() {
    this.router.navigateByUrl('profile');
    this.profilePage = true;
  }
}

