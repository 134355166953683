import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';

@Injectable({
  providedIn: 'root'
})
export class CastService {
  // Framework handlers
  chromeCast: any;
  castFrame: any;

  // Framework window names
  chromeTag = 'chrome';
  castTag = 'cast';

  // Cast instances
  castSession: any;
  castContext: any;

  // Timer object
  mediaTimer: any;

  // Holds the sending status boolean
  private sending = new BehaviorSubject<boolean>(false);

  // Holds the data about the Cast Device
  private castDevice = new BehaviorSubject<any>(null);

  // Receiver Application Id(s)
  customReceiver = '60FF4C10';
  defaultReceiver: string;

  // MessageListener namespace
  statusMessageListenerNamespace = 'urn:x-cast:com.codeheroescast.status';

  constructor() {
  }

  // Setup Sender
  initializeSender() {
    this.chromeCast = window[this.chromeTag].cast;
    this.castFrame = window[this.castTag];

    this.defaultReceiver = this.chromeCast.media.DEFAULT_MEDIA_RECEIVER_APP_ID;

    const sessionRequest = new this.chromeCast.SessionRequest((this.customReceiver || this.defaultReceiver));
    const apiConfig = new this.chromeCast.ApiConfig(
      sessionRequest,
      (session) => {
        if (session) {
          console.log('Current active session: ', session);
          this.castSession = new this.castFrame.framework.CastSession(session, this.castFrame.SessionState);
          this.setCastDevice();
          this.messageListenersSetup();
        } else {
          console.log('No active session');
          this.setSenderStatus(false);
        }
      },
      (status) => {
        if (status === this.chromeCast.ReceiverAvailability.AVAILABLE) {
          console.log('Receiver(s): ', status);
        }
      },
      this.chromeCast.AutoJoinPolicy.ORIGIN_SCOPED);

    // Initializes chrome.cast
    this.chromeCast.initialize(apiConfig, this.onInitSuccess, this.onError);

    const options = {
      receiverApplicationId: (this.customReceiver || this.defaultReceiver),
      autoJoinPolicy: this.chromeCast.AutoJoinPolicy.ORIGIN_SCOPED,
      resumeSavedSession: true
    };

    // Initializes cast.framework
    this.castFrame.framework.CastContext.getInstance().setOptions(options);
    this.castContext = this.castFrame.framework.CastContext.getInstance();
    this.castContext.addEventListener(
      this.castFrame.framework.CastContextEventType.SESSION_STATE_CHANGED,
      (event) => {
        if (event.sessionState === 'SESSION_ENDED') {
          this.stopCasting();
        }
      });
  }

  // On successful initialization.
  onInitSuccess() {
    console.log('Google Cast initialization success');
  }

  // On error.
  onError(error) {
    console.log('Google Cast initialization failed: ', error);
  }

  // Looks for Chromecast devices to cast to, and setups the session between the sender and receiver
  discoverCastDevices() {
    this.castContext.requestSession().then(() => {
      // Get current session and save it in castSession variable for use throughout the service
      this.castSession = this.castContext.getCurrentSession();

      this.setCastDevice();

      // Set device to muted
      this.castSession.setMute(true);

      // Set volume to 0
      if (this.castSession.getVolume > 0) {
        this.castSession.setVolume(0);
      }

      // Setup the messageListeners
      this.messageListenersSetup();
    }).catch(error => {
      this.setSenderStatus(false);
      console.log('Something went wrong: ', error);
    });
  }

  // Adds the messageListeners to the castSession
  messageListenersSetup() {
    // Status messageListener
    this.castSession.addMessageListener(this.statusMessageListenerNamespace, (namespace, message) => {
      if (message === '"DISCONNECTED"') {
        this.stopCasting();
      }
    });

    // Send a media item every 4 minutes in order to avoid the automatic disconnect after 5 minutes if playerState is IDLE
    this.mediaTimer = setInterval(() =>
        this.sendMediaToReceiver('../../../assets/m4m.jpg', 'image/png')
      , 240000);

    this.setSenderStatus(true);
  }

  // Send media item(s) to the Receiver App
  sendMediaToReceiver(currentMediaURL, contentType) {
    // Creates a new mediaInfo item with the given media url and contentType
    const mediaInfo = new this.chromeCast.media.MediaInfo(currentMediaURL, contentType);

    // Creates a request that will be send to the Receiver App
    const request = new this.chromeCast.media.LoadRequest(mediaInfo);

    // If session is active the media item will be send to the Receiver App
    if (this.castSession) {
      this.castSession.loadMedia(request, this.onSendMediaSuccess, this.onSendMediaError);
    } else {
      console.log('No active session');
    }
  }

  // On media success
  onSendMediaSuccess() {
    console.log('Media send success');
  }

  // On media failure
  onSendMediaError(error) {
    // Stop the media timer
    clearInterval(this.mediaTimer);
    console.log('Media send failed: ', error);
  }

  // Stops the current cast session.
  stopCasting() {
    // End current session
    this.castSession.endSession(true);

    // Stop the media timer
    clearInterval(this.mediaTimer);

    this.setSenderStatus(false);
  }

  // Sets the Sender Status
  setSenderStatus(boolean) {
    this.sending.next(boolean);
  }

  // Returns the Sender Status
  getSenderStatus() {
    return this.sending;
  }

  // Retrieves Cast Device information from the castSession
  setCastDevice() {
    const receiver = this.castSession.getCastDevice();
    this.castDevice.next(receiver);
  }

  // Returns Cast Device information
  getCastDevice() {
    return this.castDevice;
  }
}
