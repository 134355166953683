import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTabsModule} from '@angular/material/tabs';
import {WrapperComponent} from './wrapper/wrapper.component';
import {NavbarComponent} from './navbar/navbar.component';
import {MomentModule} from 'ngx-moment';
import {CastDialogComponent} from '../dialogs/cast-dialog/cast-dialog.component';
import {AnalyzeService} from './analyze.service';

@NgModule({
  imports: [
    MomentModule,
    CommonModule,
    MatToolbarModule,
    MatTabsModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule
  ],
  declarations: [WrapperComponent, NavbarComponent],
  exports: [WrapperComponent, NavbarComponent],
  entryComponents: [CastDialogComponent],
  providers: [AnalyzeService]
})
export class SharedModule {
}
