import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivityFeedOverviewComponent } from './activity-feed-overview/activity-feed-overview.component';
import { ActivityFeedItemComponent } from './activity-feed-item/activity-feed-item.component';
import { SharedModule } from '../shared/shared.module';
import { DialogsModule } from '../dialogs/dialogs.module';
import { UserCardDialogComponent } from '../dialogs/user-card-dialog/user-card-dialog.component';
import { MomentModule } from 'ngx-moment';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@NgModule({
  imports: [CommonModule, MomentModule, MatCardModule, MatProgressBarModule, SharedModule, DialogsModule],
  declarations: [ActivityFeedOverviewComponent, ActivityFeedItemComponent],
  exports: [ActivityFeedOverviewComponent, ActivityFeedItemComponent],
  entryComponents: [UserCardDialogComponent],
})
export class ActivityFeedModule {}
