<div class="root">
  <div class="header row">
    <div class="title columns small-12">Link accounts</div>
  </div>
  <div class="accounts">
    <div class="gitHub row align-middle">
      <div class="gitAvatar"></div>
      <div class="typeTitle columns">GitHub</div>
      <button class="linkButton columns" mat-raised-button (click)="linkGitHubAccount()">LINK</button>
    </div>
  </div>
</div>