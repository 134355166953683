import {Component} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {IActivity} from '@move4mobile/m4m-common-types/lib/code-heroes/models';

@Component({
  selector: 'app-activity-feed-overview',
  templateUrl: './activity-feed-overview.component.html',
  styleUrls: ['./activity-feed-overview.component.scss']
})
export class ActivityFeedOverviewComponent {
  loading = true;
  activities: IActivity[] = [];

  constructor(db: AngularFirestore) {
    const maxItems = 20;
    const activitiesRef = db.collection<IActivity>('activities', ref => ref.orderBy('timestamp', 'desc').limit(maxItems));
    activitiesRef.stateChanges(['added', 'removed']).subscribe(data => {
      return data.map((s: any) => {
        const result = s.payload.doc.data();
        if (s.payload.type === 'added') {
          this.activities.push(result);
          this.activities.sort(function compare(a, b) {
            const dateA = +new Date(a.timestamp);
            const dateB = +new Date(b.timestamp);
            return dateA - dateB;
          }).reverse();
          if (this.activities.length > maxItems) {
            this.activities.splice(maxItems);
          }
        } else if (s.payload.type === 'removed') {
          this.activities.splice(s.payload.oldIndex, 1);
        }
        this.loading = false;
      });
    });
  }
}
