<div *ngIf="user && activity.eventType">
  <div class="container row align-center my-animation">
    <div class="infoContainer row align-middle">
      <div class="profileImg" [ngStyle]="{'background-image': 'url(' + user.photoUrl+ ')'}"></div>
      <div class="column">
        <div class="row align-left">
          <div class="cardTitle columns">{{user.gameName ? user.gameName : user.displayName}} {{activityText}}</div>
        </div>
        <div class="row">
          <div *ngIf="activity.repo" class="repoText columns">
            {{activity.timestamp | amTimeAgo}} @{{activity.repo}}
          </div>
          <div *ngIf="activityDescription" class="repoText columns">
            {{activity.timestamp | amTimeAgo}} {{activityDescription}}
          </div>
        </div>
        <div class="row">
          <div class="columns small-7">
            <div class="row">
              <div *ngFor="let chip of chips">
                <div class="chip" [ngStyle]="{'background-color': chip.color}">{{chip.name}}</div>
              </div>
            </div>
          </div>
          <div>
          </div>
        </div>
      </div>
    </div>
    <div class="logo">
      <div class="typeAvatar" [ngStyle]="{'background-image': 'url(' + typeImg + ')'}"></div>
    </div>
  </div>
</div>
<div *ngIf="user && !activity.eventType">
  <div class="infoContainer row align-middle">
    <div class="profileImg" [ngStyle]="{'background-image': 'url('+user.photoUrl +')'}"></div>
    <div class="column message">
      <div class="row">
        <div class="columns">{{activity.message}}</div>
      </div>
      <div class="timeIndication columns">
        {{activity.timestamp | amTimeAgo}}
      </div>
    </div>
  </div>
</div>
