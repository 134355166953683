<mat-card class="card-fade-in">
  <div class="header row">
    <div class="cardTitle columns small-12">All competing teams</div>
    <div class="cardDescription columns">An overview of all of the competing teams
    </div>
  </div>

  <div *ngIf="teams" class="teams">
    <div *ngFor="let team of teams; let i = index">
      <app-team-item [currentGame]="currentGame" [team]="team" [rank]="i + 1"></app-team-item>
    </div>
  </div>
</mat-card>
