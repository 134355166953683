import {Component, OnInit, Input, OnChanges, Output, EventEmitter} from '@angular/core';
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import {IUser} from '@move4mobile/m4m-common-types/lib/code-heroes/models';
import { MatSnackBar } from '@angular/material/snack-bar';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-edit-details-card',
  templateUrl: './edit-details-card.component.html',
  styleUrls: ['./edit-details-card.component.scss']
})

export class EditDetailsCardComponent implements OnInit, OnChanges {

  @Input() user: IUser;

  @Output() edit: EventEmitter<any> = new EventEmitter();

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  displayNameFormControl = new FormControl('', [
    Validators.required
  ]);

  roles = [
    {value: 'web_dev', viewValue: 'Web Developer'},
    {value: 'ios_dev', viewValue: 'IOS Developer'},
    {value: 'android_dev', viewValue: 'Android Developer'},
    {value: 'fullstack_dev', viewValue: 'Fullstack Developer'},
    {value: 'backend_dev', viewValue: 'Backend Developer'},
  ];
  formModel;
  unchangedError = false;
  matcher = new MyErrorStateMatcher();


  constructor(public snackBar: MatSnackBar) {
  }


  ngOnInit() {
  }

  ngOnChanges(changes) {
    if (changes.user) {
      this.formModel = Object.assign({}, changes.user.currentValue);
      if (changes.user.currentValue && !changes.user.currentValue.gameName) {
        this.formModel.gameName = changes.user.currentValue.displayName;
      }
    }
  }

  onSubmit() {
    const editedUser = Object.assign({}, this.formModel);
    this.edit.emit(editedUser);
    this.snackBar.open('Saved user data!', '', {
      duration: 2000,
      horizontalPosition: 'left',
      panelClass: ['snackbar-style']
    });
  }
}
