<mat-card class="card-fade-in">
  <div class="header row">
    <div class="cardTitle columns small-12">Your current team</div>
    <div class="cardDescription columns">An overview of your current team
    </div>
  </div>
  <div class="row align-middle currentTeamHeader">
    <div class="profileImg" [ngStyle]="{'background-image': 'url('+ currentTeam.avatar + ')' }"></div>
    <div class="column align-left">
      <div class="columns name"> {{currentTeam.name}} </div>
      <div class="columns memberCount"> {{memberCount}} members</div>
    </div>
    <div class="columns small-2 score">{{currentTeam.score}}</div>
  </div>
  <hr>
  <div *ngIf="members">
    <div *ngFor="let member of members" class="members">
      <app-team-member-item [member]="member"></app-team-member-item>
    </div>
  </div>
</mat-card>
