import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {AngularFirestore} from '@angular/fire/firestore';
import {Team} from '@move4mobile/m4m-common-types/lib/code-heroes/models';

@Component({
  selector: 'app-cast-overview',
  templateUrl: './cast-overview.component.html',
  styleUrls: ['./cast-overview.component.scss']
})
export class CastOverviewComponent {
  db: any;
  teams: Team[];
  currentGame: string;
  activities = [];
  loading = true;

  constructor(public router: Router, db: AngularFirestore) {
    this.db = db;
    this.getCurrentGame();
    this.getActivities();
  }

  static sortByScore(array) {
    array.sort((a: any, b: any) => {
      if (a.score > b.score) {
        return -100;
      } else if (a.score < b.score) {
        return 100;
      } else {
        return 0;
      }
    });
    return array;
  }

  async getActivities() {
    const maxItems = 8;
    const activitiesRef = await this.db.collection('activities', ref => ref.orderBy('timestamp', 'desc').limit(maxItems));
    activitiesRef.stateChanges(['added', 'removed']).subscribe(data => {
      return data.map(s => {
        const result = s.payload.doc.data();
        if (s.payload.type === 'added') {
          this.activities.push(result);
          this.activities.sort(function compare(a, b) {
            const dateA = +new Date(a.timestamp);
            const dateB = +new Date(b.timestamp);
            return dateA - dateB;
          }).reverse();
          if (this.activities.length > maxItems) {
            this.activities.splice(maxItems);
          }
        } else if (s.payload.type === 'removed') {
          this.activities.splice(s.payload.oldIndex, 1);
        }
        this.loading = false;
      });
    });
  }

  getCurrentGame() {
    this.db.collection('games', ref => ref.where('status', '==', 'IN_PROGRESS')).valueChanges().subscribe(data => {
      if (data[0] !== undefined) {
        this.currentGame = data[0].id;
        this.getTeams(this.currentGame);
      }
    });
  }

  async getTeams(game) {
    await this.db.collection('games/' + game + '/teams').valueChanges().subscribe(
      (data: Team[]) => {
        this.teams = CastOverviewComponent.sortByScore(data);
      });
    this.loading = false;
  }

  home() {
    this.router.navigateByUrl('leaderboards');
  }
}
