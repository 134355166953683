import {Component, Input, OnChanges} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {IBadge, IBadgeRank, IUserBadge} from '@move4mobile/m4m-common-types/lib/code-heroes/models';

@Component({
  selector: 'app-badge-earned-item',
  templateUrl: './badge-earned-item.component.html',
  styleUrls: ['./badge-earned-item.component.scss']
})
export class BadgeEarnedItemComponent implements OnChanges {
  @Input() badge: IUserBadge;
  rank: IBadgeRank;

  constructor(private afs: AngularFirestore) {
  }

  ngOnChanges(changes) {
    if (changes.badge.currentValue) {
      if (this.badge.rank !== 0) {
        this.getAdditionalRankInfo(this.badge.badge_id);
      }
    }
  }

  async getAdditionalRankInfo(key) {
    const ranks = await this.afs.doc<IBadge>(`badges/${key}`).valueChanges();
    ranks.subscribe(
      (data: IBadge) => {
        this.rank = data.ranks[this.badge.rank - 1];
      });
  }
}
