import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {AuthService} from '../../auth/auth.service';

@Component({
  selector: 'app-link-accounts-dialog',
  templateUrl: './link-accounts-dialog.component.html',
  styleUrls: ['./link-accounts-dialog.component.scss']
})
export class LinkAccountsDialogComponent {
  auth: any;

  constructor(public dialogRef: MatDialogRef<LinkAccountsDialogComponent>, auth: AuthService) {
    this.auth = auth;
  }

  linkGitHubAccount() {
    this.auth.linkGitHubAccount().then(() => {
      this.closeDialog();
    });

  }

  closeDialog() {
    this.dialogRef.close();
  }

}
