<div *ngIf="team" class="row content align-middle">
  <div *ngIf="team" class="teamAvatar column small-5">
    <div class="row align-middle">
      <div class="position">{{rank}}.</div>
      <!-- <div class="teamImage" [ngStyle]="{'border': '3px solid' + color, 'background-image': 'url(' + team.avatar + ')'}" ></div> -->
      <div class="teamName column">{{team.name}}</div>

    </div>
    <div *ngIf="members" class="row">
      <div *ngFor="let member of members | slice:1:8; let lastItem = last;" class="row columns members">
        <div class="memberImg"
             [ngStyle]="{'border': '3px solid' + color, 'background-image': 'url('+ member.avatar + ')' }"></div>
        <div *ngIf="lastItem && members.length > 8" class="more">
          +{{members.length - 8}}
        </div>
      </div>
    </div>
    <!-- <div *ngIf="!members" class="row">
        <div *ngFor="let dummy of ' '.repeat(8).split(''), let x = index" class="row columns members">
            <div class="memberImg" [ngStyle]="{'border': '3px solid' + color, 'background': 'lightgrey'}"></div>
        </div>
    </div> -->

  </div>
  <div class="column columns align-right">
    <div class="row mvp columns" *ngIf="mvp">
      <div class="mvpImage" [ngStyle]="{'border': '3px solid' + color, 'background-image': 'url(' + mvp.avatar + ')'}">
        <div class="mvpText" [ngStyle]="{'background-color': color}">MVP</div>
      </div>
    </div>
  </div>
  <div class="column small-1">
    <div class="chipContainer" [ngStyle]="{'background-color': color}">
      <div class="chipText">
        {{team.score}}
      </div>
    </div>
  </div>
</div>
<hr *ngIf="!last">
