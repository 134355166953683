<mat-toolbar>
  <mat-toolbar-row class="align-middle">
    <mat-tab-group [ngClass]="color" disableRipple [selectedIndex]="selectedIndex"
                   (selectedTabChange)="navigate($event)">
      <div *ngFor="let route of navigationRoutes">
        <mat-tab isActive="true" label="{{route.name}}"></mat-tab>
      </div>
    </mat-tab-group>
    <span class="spacer"></span>
    <button *ngIf="!casting" class="castButton" mat-icon-button (click)="cast()">
      <mat-icon>cast</mat-icon>
    </button>
    <button *ngIf="casting" class="castButton" mat-icon-button (click)="stopCast()">
      <mat-icon class="castConnected">cast_connected</mat-icon>
    </button>
    <div *ngIf="auth.userData && !profilePage" class="profileImg" (click)="navToProfile()"
         [ngStyle]="{'background-image': 'url('+ auth.userData.photoURL + ')' }">
    </div>
    <button *ngIf="profilePage" class="castButton" mat-icon-button (click)="auth.logout()">
      <mat-icon>input</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>
