import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

import {AngularFireAuth} from '@angular/fire/auth';
import {AngularFireDatabase} from '@angular/fire/database';
import {AngularFirestore, AngularFirestoreDocument} from '@angular/fire/firestore';
import {Observable} from 'rxjs-compat';
import * as firebase from 'firebase/app';

interface User {
  uid: string;
  email: string;
  photoURL?: string;
  displayName?: string;
  gameName?: string | null;
  role?: string | null;
  dateOfBirth?: string | null;
  score?: number;
}

@Injectable()
export class AuthService {
  user: Observable<User>;

  userId: string;
  userData: Object;

  constructor(public afAuth: AngularFireAuth,
              private afs: AngularFirestore,
              private db: AngularFireDatabase,
              private router: Router) {
    this.user = this.afAuth.authState.switchMap(user => {
      if (user) {
        return this.afs.doc<User>(`users/${user.email}`).valueChanges();
      } else {
        return Observable.of(null);
      }
    });

    this.afAuth.authState.do(user => {
      if (user) {
        this.userId = user.email;
        this.userData = user;
      }

    }).subscribe();
  }


  public googleLogin() {
    const provider = new firebase.auth.GoogleAuthProvider();
    return this.oAuthLogin(provider);
  }

  private oAuthLogin(provider) {
    return this.afAuth.auth.signInWithPopup(provider)
      .then((credentials) => {
        this.updateUserData(credentials.user);
        this.updateAccountsData(credentials.user).then(() => {
          this.router.navigate(['leaderboards']);
        });
      });
  }

  private oAuthLinkLogin(provider) {
    return this.afAuth.auth.currentUser.linkWithPopup(provider)
      .then((credentials) => {
        this.updateGitAccounts(credentials);
      }).catch(d => {
        console.log(d);
      });
  }

  private updateGitAccounts(creds) {
    const accountRef = this.afs.collection(`users/${creds.user.email}/accounts`).doc('github_' + creds.additionalUserInfo.username);
    const accountsRef = this.afs.collection(`accounts`);

    console.log(creds);
    const accountsData = {
      displayName: creds.additionalUserInfo.name,
      username: creds.additionalUserInfo.username,
      userRef: creds.user.email,
      source: 'github',
      photoUrl: 'https://github.com/' + creds.additionalUserInfo.username + '.png?size=200'

    };
    const data = {
      type: 'github',
      username: creds.additionalUserInfo.username,
      token: creds.credential.accessToken
    };

    accountsRef.doc('github_' + creds.additionalUserInfo.username).set(accountsData);
    return accountRef.set(data);
  }

  private updateAccountsData(user) {
    const accountRef = this.afs.collection(`users/${user.email}/accounts`).doc(`google_` + user.email);

    const data = {
      type: 'google',
      uid: user.uid,
      email: user.email
    };
    return accountRef.set(data);
  }

  private updateUserData(user): any {
    const userRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${user.email}`);
    const data: User = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      score: 0
    };

    return userRef.valueChanges().subscribe(res => {
      if (res) {
        userRef.update(data);
      } else {
        userRef.set(data);
      }
    });
  }

  public logout() {
    this.afAuth.auth.signOut();
    this.userData = null;
    this.router.navigate(['/login']);
  }

  public linkGitHubAccount() {
    const provider = new firebase.auth.GithubAuthProvider();
    return this.oAuthLinkLogin(provider);
  }

}


