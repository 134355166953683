import {Component, OnInit, Input} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {Player, Team} from '@move4mobile/m4m-common-types/lib/code-heroes/models';
import {Util} from '../../shared/util';

@Component({
  selector: 'app-team-item',
  templateUrl: './team-item.component.html',
  styleUrls: ['./team-item.component.scss']
})
export class TeamItemComponent implements OnInit {
  @Input() team: Team;
  @Input() rank: number;
  @Input() currentGame: string;

  members: Player[];
  color: string;

  constructor(private db: AngularFirestore) {
  }

  ngOnInit() {
    switch (this.rank) {
      case 1:
        this.color = '#FFD700';
        break;
      case 2:
        this.color = '#919191';
        break;
      case 3:
        this.color = '#664426';
        break;
      case 4:
        this.color = '#434343';
        break;
    }
    this.getMembers();
  }

  getMembers() {
    this.db.collection<Player>('games/' + this.currentGame + '/players', ref =>
      ref.where('teamID', '==', this.team.id)).valueChanges().subscribe(
      (data: Player[]) => {
        this.members = Util.sortByScore(data);
      });
  }
}
