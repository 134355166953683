<div *ngIf="!users" class="container row align-center">
  <div class="secondSkeleton column">
    <div class="positionSkeleton row align-center">
      <div class="positionNumberSkeleton">
      </div>
    </div>
    <div class="imgSkeleton"></div>
    <div class="infoSkeleton">
    </div>
    <div class="chipContainerSkeleton">
      <div class="chipSkeleton">
      </div>
    </div>
  </div>
  <div class="firstSkeleton column">
    <div class="positionSkeleton row align-center">
      <div class="positionNumberSkeleton">
      </div>
    </div>
    <div class="imgWinnerSkeleton"></div>
    <div class="infoSkeleton">
    </div>
    <div class="chipContainerSkeleton">
      <div class="chipSkeleton">
      </div>
    </div>
  </div>
  <div class="thirdSkeleton column">
    <div class="positionSkeleton row align-center">
      <div class="positionNumberSkeleton">
      </div>
    </div>
    <div class="imgSkeleton"></div>
    <div class="infoSkeleton">
    </div>
    <div class="chipContainerSkeleton">
      <div class="chipSkeleton">
      </div>
    </div>
  </div>
</div>
<div *ngIf="users" class="container row align-center">
  <div class="second column">
    <div class="position row align-center">
      <!-- <div class="positionChange">
        <img src="../../assets/position_{{second.positionChange}}.svg"/>
      </div> -->
      <div class="positionNumber">
        2
      </div>
    </div>
    <div *ngIf="users[1].avatar" (click)="openDialog(users[1])" class="avatar align-center"
         [ngStyle]="{'background-image': 'url(' + users[1].avatar + ')'}"></div>
    <div *ngIf="users[1].isTeamCaptain" class="captain">C</div>
    <div class="info">
      <div class="name">
        {{ (users[1].displayName.length > 6) ? (users[1].displayName | slice:0:6) + '..' : (users[1].displayName) }}
      </div>
      <div *ngIf="users[1].role" class="subName">
        {{users[1].role}}
      </div>
    </div>
    <div class="chipContainer">
      <div class="chip">
        {{users[1].score}}
      </div>
    </div>
  </div>
  <div class="first column">
    <div class="position row align-center">
      <!-- <div class="positionChange">
        <img src="../../assets/position_{{winner.positionChange}}.svg"  />
      </div> -->
      <div class="positionNumber">
        1
      </div>
    </div>
    <div class="winnerAvatar align-center" (click)="openDialog(users[0])"
         [ngStyle]="{'background-image': 'url(' + users[0].avatar + ')'}"></div>
    <div *ngIf="users[0].isTeamCaptain" class="captain">C</div>
    <div class="info">
      <div class="name">
        {{ (users[0].displayName.length > 6) ? (users[0].displayName | slice:0:6) + '..' : (users[0].displayName) }}
      </div>
      <div class="subName">
        {{users[0].role}}
      </div>
    </div>
    <div class="chipContainer">
      <div class="chip">
        {{users[0].score}}
      </div>
    </div>
  </div>
  <div class="third column">
    <div class="position row align-center">
      <!-- <div class="positionChange">
        <img src="../../assets/position_{{third.positionChange}}.svg" />
      </div> -->
      <div class="positionNumber">
        3
      </div>
    </div>
    <div class="avatarContainer">
      <div class="avatar" (click)="openDialog(users[2])"
           [ngStyle]="{'background-image': 'url(' + users[2].avatar + ')'}"></div>
    </div>
    <div *ngIf="users[2].isTeamCaptain" class="captain">C</div>
    <div class="info">
      <div class="name">
        {{ (users[2].displayName.length > 6) ? (users[2].displayName | slice:0:6) + '..' : (users[2].displayName) }}
      </div>
      <div class="subName">
        {{users[2].role}}
      </div>
    </div>
    <div class="chipContainer">
      <div class="chip">
        {{users[2].score}}
      </div>
    </div>
  </div>
</div>
