<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
<mat-toolbar>
  <span class="fill-space"></span>
  <button (click)="home()" mat-icon-button>
    <mat-icon style="color: white;">cancel_presentation</mat-icon>
  </button>
</mat-toolbar>
<div *ngIf="!loading" class="content row align-center">
  <mat-card *ngIf="!loading" class="leaderboard card-fade-in">
    <div class="title">Current game team ranking</div>
    <div *ngIf="teams" class="fade-in">
      <div class="leaderboardContent" *ngFor="let team of teams; let i = index">
        <app-cast-leaderboard-item [team]="team" [currentGame]="currentGame" [rank]="i + 1"
                                   [attr.data-index]="i"></app-cast-leaderboard-item>
      </div>
    </div>
  </mat-card>
  <mat-card *ngIf="!loading" class="activityFeed columns card-fade-in align-right">
    <div class="title">Activity</div>
    <div *ngFor="let activity of activities" class="my-animation">
      <app-cast-activity-item [activity]="activity"></app-cast-activity-item>
    </div>
  </mat-card>
</div>
