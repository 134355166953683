import {Component, Input, OnChanges} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {IBadge, IUser} from '@move4mobile/m4m-common-types/lib/code-heroes/models';

@Component({
  selector: 'app-badges-progress-card',
  templateUrl: './badges-progress-card.component.html',
  styleUrls: ['./badges-progress-card.component.scss']
})
export class BadgesProgressCardComponent implements OnChanges {
  badges: IBadge[] = [];
  loading = true;

  @Input() currentUser: IUser;

  constructor(private afs: AngularFirestore) {
  }

  ngOnChanges(changes) {
    if (changes.currentUser.currentValue !== undefined) {
      const key = changes.currentUser.currentValue.email;
      this.getCurrentUsersBadges(key);
    }
  }

  async getCurrentUsersBadges(key) {
    await this.afs.doc<IBadge>(`users/${key}`).collection('badges').valueChanges().subscribe(
      (data: IBadge[]) => {
        this.badges = data;
        this.loading = false;
      });
  }
}
