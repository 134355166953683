import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {UserCardDialogComponent} from './user-card-dialog/user-card-dialog.component';
import {CastDialogComponent} from './cast-dialog/cast-dialog.component';

@Injectable()
export class DialogsService {

  constructor(public dialog: MatDialog) {
  }

  public openDialog(type, user) {
    switch (type) {
      case 'user':
        this.openUserDialog(user);
        break;
    }
  }

  openUserDialog(user): void {
    const dialogRef = this.dialog.open(UserCardDialogComponent, {
      data: {
        user: user
      },
      panelClass: 'custom-dialog'
    });
  }

  openCastDialog(): void {
    this.dialog.open(CastDialogComponent);
  }
}
