import {Component, OnInit} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {Game} from '@move4mobile/m4m-common-types/lib/code-heroes/models';
import {Util} from '../../shared/util';

@Component({
  selector: 'app-game-overview',
  templateUrl: './game-overview.component.html',
  styleUrls: ['./game-overview.component.scss']
})
export class GameOverviewComponent implements OnInit {

  loading = true;
  games: Game[];

  constructor(private afs: AngularFirestore) {
  }

  ngOnInit() {
    this.getAllGames();
  }

  getAllGames() {
    this.afs.collection('games', ref => ref.where('status', '==', 'CLOSED')
      .orderBy('startedAt', 'desc')).valueChanges().subscribe(
      (games: Game[]) => {
        this.games = games;
        for (const game of this.games) {
          this.getUsersInGame(game.id).subscribe(playersData => {
            playersData = Util.sortByScore(playersData);
            Object.assign(game, {players: playersData});
          });
        }
      });
  }

  getUsersInGame(game) {
    return this.afs.collection('games').doc(game).collection('players').valueChanges();
  }
}
