import {Component, OnInit, Input} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {IBadge, IBadgeRank, IUserBadge} from '@move4mobile/m4m-common-types/lib/code-heroes/models';

@Component({
  selector: 'app-badge-progress-item',
  templateUrl: './badge-progress-item.component.html',
  styleUrls: ['./badge-progress-item.component.scss']
})
export class BadgeProgressItemComponent implements OnInit {
  @Input() badge: IUserBadge;
  rank: IBadgeRank;
  color: Object;
  avatar: string;

  constructor(private afs: AngularFirestore) {
  }

  ngOnInit() {
    this.getProgression();
  }

  async getProgression() {
    const ranks = await this.afs.doc<IBadge>(`badges/${this.badge.badge_id}`).valueChanges();
    ranks.subscribe(
      (data: IBadge) => {
        this.rank = data.ranks[this.badge.rank];
        this.getProgressBarColor();
      });
  }

  getProgressBarColor() {
    const progress = (this.badge.event_occ / this.rank.needed_occ) * 100;

    if ((progress < 25)) {
      this.color = {
        'red': true,
        'orange': false,
        'yellow': false,
        'green': false
      };
    } else if ((progress < 50)) {
      this.color = {
        'red': false,
        'orange': true,
        'yellow': false,
        'green': false
      };
    } else if ((progress < 75)) {
      this.color = {
        'red': false,
        'orange': false,
        'yellow': true,
        'green': false
      };
    } else if ((progress < 100)) {
      this.color = {
        'red': false,
        'orange': false,
        'yellow': false,
        'green': true
      };
    }
  }
}
