import { Component, Inject } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AnalyzeService } from '../../shared/analyze.service';
import { IActivity, Game, IUserBadge, Team, Player } from '@move4mobile/m4m-common-types/lib/code-heroes/models';
import { Util } from '../../shared/util';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-user-card-dialog',
  templateUrl: './user-card-dialog.component.html',
  styleUrls: ['./user-card-dialog.component.scss'],
})
export class UserCardDialogComponent {
  user: Player;
  userId: string;
  teamId: string;
  badge: IUserBadge;
  team: Team;
  activity: IActivity;
  activityText: string;

  typeImg: string;
  loading = true;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private afs: AngularFirestore, private analyzeService: AnalyzeService) {
    this.userId = data.user.email || data.user.id;
    this.getCurrentGame();
  }

  capitalizeFirstLetter(string) {
    return string[0].toUpperCase() + string.slice(1);
  }

  getData(teamId) {
    this.getUserRecentActivity(this.userId);
    this.getUserDisplayedBadges(this.userId);
    this.getUserCurrentTeam(teamId);
  }

  getCurrentGame() {
    this.afs
      .collection<Game>('games', ref => ref.where('status', '==', 'IN_PROGRESS'))
      .valueChanges()
      .subscribe((data: Game[]) => {
        this.getPlayerData(data[0].id);
      });
  }

  getPlayerData(currentGameId) {
    this.afs
      .collection('games/' + currentGameId + '/players')
      .doc(this.userId)
      .valueChanges()
      .subscribe((data: any) => {
        this.teamId = data.teamID;
        this.user = data;
        if (this.user) {
          this.getData(this.teamId);
        }
      });
  }

  getUserDisplayedBadges(userId) {
    this.afs
      .collection<IUserBadge>('users/' + userId + '/badges')
      .snapshotChanges()
      .map(data => {
        return data.map(u => {
          const result = u.payload.doc.data();
          const id = u.payload.doc.id;
          return { id, ...result };
        });
      })
      .subscribe(async (badges: IUserBadge[]) => {
        this.badge = Util.sortByScore(badges)[0];
      });
  }

  getUserRecentActivity(userId) {
    const activitiesRef = this.afs.collection<IActivity>('activities', ref =>
      ref
        .where('user', '==', userId)
        .orderBy('timestamp', 'desc')
        .limit(1),
    );
    activitiesRef.stateChanges(['added', 'removed']).subscribe(data => {
      return data.map(s => {
        this.activity = s.payload.doc.data();
        this.analyzeService.analyzeActivity(this.activity);
        this.typeImg = this.analyzeService.getTypeImg();
        this.activityText = this.analyzeService.getActivityText();
        this.loading = false;
      });
    });
  }

  getUserCurrentTeam(teamId) {
    this.afs
      .collection<Team>('teams', ref => ref.where('id', '==', teamId))
      .valueChanges()
      .subscribe((data: Team[]) => {
        this.team = data[0];
      });
  }
}
