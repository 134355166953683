import {Component, OnInit} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {AuthService} from '../../auth/auth.service';
import {IUser} from '@move4mobile/m4m-common-types/lib/code-heroes/models';

@Component({
  selector: 'app-badge-page-overview',
  templateUrl: './badge-page-overview.component.html',
  styleUrls: ['./badge-page-overview.component.scss']
})
export class BadgePageOverviewComponent implements OnInit {
  auth: any;
  user: any;
  currentUser: IUser;

  constructor(auth: AuthService, private afs: AngularFirestore) {
    this.auth = auth;
  }

  ngOnInit() {
    this.user = this.auth.userData;
    this.getCurrentUser(this.user.email).subscribe(
      (data: IUser) => {
        this.currentUser = data;
      });
  }

  getCurrentUser(key) {
    return this.afs.doc<IUser>(`users/${key}`).valueChanges();
  }
}


