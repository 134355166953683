<mat-list-item *ngIf="!user" role="list-item" class="container row">
  <!-- <div *ngIf="user" class="positionChange" >
    <img src="../../assets/position_{{user.positionChange}}.svg" />
  </div> -->
  <!-- <div class="position">4.</div> -->
  <div class="profileImageSkeleton"></div>
  <div class="info">
  </div>

  <div class="chipSkeleton columns">
    <div class="chipContentSkeleton">
    </div>
  </div>
</mat-list-item>
<mat-list-item *ngIf="user" role="list-item" class="row">
  <!-- <div *ngIf="user" class="positionChange" >
    <img src="../../assets/position_{{user.positionChange}}.svg" />
  </div> -->
  <!-- <div class="position">4.</div> -->
  <div *ngIf="user" class="profileImage" (click)="invokeUserCard()"
       [ngStyle]="{'background-image': 'url(' + user.avatar + ')'}">
    <div *ngIf="user.isTeamCaptain" class="captain">C</div>
  </div>
  <div *ngIf="user" class="info columns small-6">
    <div class="name">
      {{user.displayName}}
    </div>
    <div *ngIf="user" class="subname">
      {{user.role}}
    </div>
  </div>

  <div class="chip columns">
    <div *ngIf="user" class="chipContent">
      {{user.score}}
    </div>
  </div>
</mat-list-item>
