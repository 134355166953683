import {Component, Inject, OnInit} from '@angular/core';
import {CastService} from '../../shared/navbar/cast.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-cast-dialog',
  templateUrl: './cast-dialog.component.html',
  styleUrls: ['./cast-dialog.component.scss']
})
export class CastDialogComponent implements OnInit {
  castDevice: any;

  constructor(private castService: CastService, public dialogRef: MatDialogRef<CastDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    this.castService.getCastDevice().subscribe(
      (data) => {
        this.castDevice = data;
      }
    );
  }

  stop() {
    this.castService.stopCasting();
    this.dialogRef.close();
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
