<div class="root row align-center">
  <div *ngIf="games">
    <mat-card class="card-fade-in" *ngFor="let game of games">
      <div class="header row">
        <div class="cardTitle columns small-12">Game {{ game.startedAt.seconds * 1000 | date }}
          - {{ game.closedAt.seconds * 1000 | date }}</div>
        <div class="cardDescription columns">Top 4 players of this game</div>
      </div>
      <div *ngIf="game.players" class="row players">
        <div *ngFor="let player of game.players | slice:0:4" class="columns player">
          <div class="row columns memberImg"
               [ngStyle]="{'border': '1px solid' + color, 'background-image': 'url('+ player.avatar + ')' }"></div>
          <div class="row columns score">{{ player.score }}</div>
        </div>
      </div>
    </mat-card>
  </div>
</div>

