<div *ngIf="activity && user" class="container row align-center">
  <div class="card my-animation">
    <mat-card>
      <div *ngIf="!activity || !user">
        <div class="infoContainerSkeleton row align-middle">
          <div class="profileImgSkeleton"></div>
          <div class="column">
            <div class="row align-left">
              <div class="cardTitleSkeleton columns small-10"></div>
            </div>
            <div class="row">
              <div class="cardSubTitleSkeleton columns"></div>
            </div>
            <div class="row">
              <div class="columns small-10">
                <div class="row">
                  <div class="chipSkeleton"></div>
                </div>
              </div>
              <div class="timeIndicationSkeleton columns">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="user && activity && activity.eventType">
        <div class="infoContainer row align-middle">
          <div class="profileImg" (click)="openUserDialog()"
               [ngStyle]="{'background-image': 'url('+user.photoUrl +')'}"></div>
          <div class="column">
            <div class="row align-left">
              <div
                class="cardTitle columns small-10">{{user.displayName ? user.displayName : user.name}} {{activityText}}</div>
            </div>
            <div *ngIf="activity.repo" class="row">
              <div class="cardSubTitle columns">@{{activity.repo}}</div>
            </div>
            <div *ngIf="activityDescription" class="row">
              <div class="cardSubTitle columns">{{activityDescription}}</div>
            </div>
            <div class="row">
              <div class="columns small-7">
                <div class="row">
                  <div *ngFor="let chip of chips">
                    <div class="chip" [ngStyle]="{'background-color': chip.color}">{{chip.name}}</div>
                  </div>
                </div>
              </div>
              <div class="timeIndication columns">
                {{activity.timestamp | amTimeAgo}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="user && !activity.eventType">
        <div class="infoContainer row align-middle">
          <div class="profileImg" (click)="openUserDialog()"
               [ngStyle]="{'background-image': 'url('+user.photoUrl +')'}"></div>
          <div class="column message">
            <div class="row">
              <div class="columns">{{activity.message}}</div>
            </div>
            <div class="timeIndication columns">
              {{activity.timestamp | amTimeAgo}}
            </div>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
  <div class="logo">
    <div class="typeAvatar" [ngStyle]="{'background-image': 'url(' + typeImg + ')'}"></div>
  </div>
</div>
