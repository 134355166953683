import {Component, Input} from '@angular/core';
import {DialogsService} from '../../dialogs/dialogs.service';
import {IUser} from '@move4mobile/m4m-common-types/lib/code-heroes/models';

@Component({
  selector: 'app-leaderboard-item',
  templateUrl: './leaderboard-item.component.html',
  styleUrls: ['./leaderboard-item.component.scss']
})
export class LeaderboardItemComponent {
  @Input() user: IUser;

  constructor(private dialog: DialogsService) {
  }

  invokeUserCard() {
    this.dialog.openDialog('user', this.user);
  }
}
