import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class AnalyzeService {
  activityText: string;
  activityDescription: string;
  chips: any;
  typeImg = '../../../assets/m4m.jpg';

  constructor(private afs: AngularFirestore) {
  }

  getTypeImg() {
    return this.typeImg;
  }

  getActivityText() {
    return this.activityText;
  }

  getActivityDescription() {
    return this.activityDescription;
  }

  getChips() {
    return this.chips;
  }

  analyzeActivity(activity: any) {
    switch (activity.eventType) {
      case 'github-events':
        this.analyzeGit(activity);
        break;
      case 'travis-events':
        this.analyzeTravis(activity);
        break;
      case 'player-events':
        this.analyzePlayerEvents(activity);
        break;
      case 'badge-events':
        this.analyzeBadges(activity);
        break;
    }
  }

  private analyzeGit(activity: any) {
    this.typeImg = '../../assets/github.png';
    switch (activity.eventData.event) {
      case 'delete':
        this.chips = [
          {
            name: 'Delete',
            color: '#434343'
          }
        ];
        this.activityText = 'deleted a ' + activity.eventData.ref_type;
        break;
      case 'push':
        this.chips = [
          {
            name: 'Push',
            color: '#127C4B'
          },
          {
            name: 'Commit',
            color: '#0FA39C'
          }
        ];
        if (activity.eventData.commits.length > 1) {
          this.activityText = 'pushed ' + activity.eventData.commits.length + ' commits';
        } else {
          this.activityText = 'pushed a commit';
        }
        break;
      case 'pull_request':
        this.chips = [
          {
            name: 'Pull Request',
            color: '#7F168A'
          }
        ];
        if (activity.eventData.action === 'opened') {
          this.activityText = 'opened a pull request';
        } else if (activity.eventData.action === 'closed') {
          this.activityText = 'closed a pull request';
        } else if (activity.eventData.action === 'merged') {
          this.activityText = 'merged a pull request';
        } else {
          this.activityText = 'contributed to a pull request';
        }
        break;
      case 'pull_request_review':
        this.chips = [
          {
            name: 'Pull Request',
            color: '#7F168A'
          }
        ];
        if (activity.eventData.review.state === 'approved') {
          this.activityText = 'approved a pull request';
          this.chips.push({
            name: 'Approved',
            color: '#55a753'
          });
        } else {
          this.activityText = 'reviewed a pull request';
          this.chips.push({
            name: 'Review',
            color: '#55a753'
          });
        }
        break;
      case 'issues':
        this.chips = [
          {
            name: 'Issue',
            color: '#bc145a'
          }
        ];
        if (activity.eventData.action === 'closed') {
          this.activityText = 'closed an issue';
          this.chips.push({
            name: 'Closed',
            color: '#c24042'
          });
        } else if (activity.eventData.action === 'opened') {
          this.activityText = 'opened an issue';
          this.chips.push({
            name: 'Opened',
            color: '#55a753'
          });
        }
        break;
      case 'release':
        this.chips = [
          {
            name: 'Release',
            color: '#224E70'
          }
        ];
        if (activity.eventData.action === 'published') {
          this.activityText = 'published a release';
          this.chips.push({
            name: 'Published',
            color: '#00B1FA'
          });
        }
        break;
    }
  }

  private analyzeTravis(activity: any) {
    this.typeImg = '../../assets/travis.png';
    switch (activity.eventData.state) {
      case 'passed':
        this.chips = [
          {
            name: 'Passed',
            color: '#40B41F'
          },
          {
            name: 'Build',
            color: '#BD5814'
          }
        ];
        if (activity.action === 'pull_request') {
          this.chips.push({
            name: 'Pull request',
            color: '#7F168A'
          });
        } else if (activity.action === 'push') {
          this.chips.push({
            name: 'Push',
            color: '#127C4B'
          });
        }
        this.activityText = 'passed a build';
        break;
      case 'failed':
        this.chips = [
          {
            name: 'Failed',
            color: '#BD0F0F'
          },
          {
            name: 'Build',
            color: '#BD5814'
          }
        ];
        if (activity.action === 'pull_request') {
          this.chips.push({
            name: 'Pull request',
            color: '#7F168A'
          });
        } else if (activity.action === 'push') {
          this.chips.push({
            name: 'Push',
            color: '#127C4B'
          });
        }
        this.activityText = 'failed a build';
        break;
      case 'errored':
        this.chips = [
          {
            name: 'Error',
            color: '#BD0F0F'
          },
          {
            name: 'Build',
            color: '#BD5814'
          }
        ];
        if (activity.action === 'pull_request') {
          this.chips.push({
            name: 'Pull request',
            color: '#7F168A'
          });
        } else if (activity.action === 'push') {
          this.chips.push({
            name: 'Push',
            color: '#127C4B'
          });
        }
        this.activityText = 'errored a build';
        break;
      case 'canceled':
        this.chips = [
          {
            name: 'Cancel',
            color: '#EBAD07'
          },
          {
            name: 'Build',
            color: '#BD5814'
          }
        ];
        if (activity.action === 'pull_request') {
          this.chips.push({
            name: 'Pull request',
            color: '#7F168A'
          });
        } else if (activity.action === 'push') {
          this.chips.push({
            name: 'Push',
            color: '#127C4B'
          });
        }
        this.activityText = 'canceled a build';
        break;
    }
  }

  private async analyzePlayerEvents(activity: any) {
    this.typeImg = activity.eventData.avatar;
    this.activityText = activity.eventData.action + ' the ' + activity.eventData.team;
    this.chips = [
      {
        name: 'Player',
        color: '#EB0774'
      },
      {
        name: activity.eventData.action,
        color: '#434343'
      }
    ];
  }

  private async analyzeBadges(activity: any) {
    const ranks: any = await this.afs.doc(`badges/${activity.id}`).valueChanges();
    ranks.subscribe(data => {
      const rank = data.ranks[activity.eventData.new_rank - 1];
      console.log(rank);
      this.activityText = 'received a new badge';
      this.activityDescription = 'For ' + activity.eventData.event_occ + ' event occurences';
      this.typeImg = '../../../assets/badges/' + rank.name + '_' + activity.eventData.badge_avatar + '.png';
      this.chips = [
        {
          name: 'Badge',
          color: '#00B1FA'
        },
        {
          name: activity.eventData.badge_name,
          color: '#434343'
        }
      ];
    });
  }
}
