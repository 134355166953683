import {Component, OnInit, Input} from '@angular/core';
import {DialogsService} from '../../dialogs/dialogs.service';
import {IUser} from '@move4mobile/m4m-common-types/lib/code-heroes/models';

@Component({
  selector: 'app-leaderboard-top-three',
  templateUrl: './leaderboard-top-three.component.html',
  styleUrls: ['./leaderboard-top-three.component.scss']
})
export class LeaderboardTopThreeComponent implements OnInit {

  @Input() users: IUser;

  constructor(private dialogs: DialogsService) {
  }

  ngOnInit() {
  }

  openDialog(key) {
    this.dialogs.openDialog('user', key);
  }
}
