export class Util {
  static sortByScore(array) {
    array.sort((a: any, b: any) => {
      if (a.score > b.score) {
        return -100;
      } else if (a.score < b.score) {
        return 100;
      } else {
        return 0;
      }
    });
    return array;
  }
}
