<div *ngIf="rank" class="container">
  <div class="row align-middle">
    <div class="badgeImg"
         [ngStyle]="{'background-image': 'url(../../../assets/badges/'+rank.name+'_'+badge.avatar_ref+'.png)'}">
    </div>
    <div class="column">
      <div class="row">
        <div class="badgeName columns">{{rank.name}} {{badge.badge_name}}</div>
        <div class="badgeProgress columns small-4">{{rank.needed_occ}}</div>
      </div>
      <div class="row badgeDescription">
        <div class="columns">{{badge.description}}</div>
      </div>
    </div>
  </div>
</div>
