import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {CastService} from './shared/navbar/cast.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  currRoute: any;

  constructor(public router: Router, private castService: CastService) {
    this.currRoute = window.location.pathname.split('/')[1];

    const codeHeroesSender = this.castService;

    window['__onGCastApiAvailable'] = function (isAvailable) {
      if (isAvailable) {
        codeHeroesSender.initializeSender();
      }
    };
  }
}
