import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {AuthService} from './auth.service';
import {AuthGuard} from './auth.guard';
import {AngularFireModule} from '@angular/fire';

@NgModule({
  imports: [
    CommonModule,
    AngularFireModule,
    AngularFirestoreModule
  ],
  declarations: [],
  providers: [
    AuthService,
    AuthGuard
  ]
})

export class AuthModule {
}
