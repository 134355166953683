<!--The content below is only a placeholder and can be replaced.-->
<!-- <div class="root" style="text-align:center">
  <router-outlet></router-outlet>
</div> -->
<div class="root" style="text-align:center">
  <app-wrapper>
    <router-outlet></router-outlet>
  </app-wrapper>
  <!--<div *ngIf="currRoute !== 'cast' && currRoute !== 'login'" class="skeleton">-->
  <!--<mat-toolbar class="skeletonToolbar">-->
  <!--<mat-toolbar-row class="align-middle">-->
  <!--<div class="skeletonRoute"></div>-->
  <!--<div class="skeletonRoute"></div>-->
  <!--<div class="skeletonRoute"></div>-->
  <!--<span class="spacer"></span>-->
  <!--<div class="skeletonIcon"></div>-->
  <!--<div class="skeletonImage"></div>-->
  <!--</mat-toolbar-row>-->
  <!--</mat-toolbar>-->
  <!--</div>-->
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>
