import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LeaderboardOverviewComponent} from './leaderboard-overview/leaderboard-overview.component';
import {LeaderboardIndividualCardComponent} from './leaderboard-individual-card/leaderboard-individual-card.component';
import {LeaderboardItemComponent} from './leaderboard-item/leaderboard-item.component';
import {LeaderboardTopThreeComponent} from './leaderboard-top-three/leaderboard-top-three.component';
import {LeaderboardGameCardComponent} from './leaderboard-game-card/leaderboard-game-card.component';
import {SharedModule} from '../shared/shared.module';
import {UserCardDialogComponent} from '../dialogs/user-card-dialog/user-card-dialog.component';
import {DialogsModule} from '../dialogs/dialogs.module';
import { MatCardModule } from '@angular/material/card';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatListModule} from '@angular/material/list';
import {MatGridListModule} from '@angular/material/grid-list';

@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
    MatGridListModule,
    MatListModule,
    SharedModule,
    DialogsModule,
    MatProgressBarModule,
  ],
  declarations: [
    LeaderboardOverviewComponent,
    LeaderboardIndividualCardComponent,
    LeaderboardItemComponent,
    LeaderboardTopThreeComponent,
    LeaderboardGameCardComponent
  ],
  exports: [LeaderboardIndividualCardComponent, LeaderboardOverviewComponent],
  entryComponents: [UserCardDialogComponent]
})
export class LeaderboardsModule {
}
