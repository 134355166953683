import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../auth/auth.service';
import {AngularFirestore} from '@angular/fire/firestore';
import {Game, Team} from '@move4mobile/m4m-common-types/lib/code-heroes/models';
import {Util} from '../../shared/util';

@Component({
  selector: 'app-team-overview',
  templateUrl: './team-overview.component.html',
  styleUrls: ['./team-overview.component.scss']
})
export class TeamOverviewComponent implements OnInit {
  db: any;
  auth: any;

  loading = true;
  teams: Team[];
  currentGame: string;
  currentTeam: Team;

  constructor(auth: AuthService, private afs: AngularFirestore) {
    this.db = afs;
    this.auth = auth;
  }

  ngOnInit() {
    this.getCurrentGame();
  }

  async getCurrentUser(game) {
    await this.db.collection('games').doc(game).collection('players').doc(this.auth.userData.email).valueChanges().subscribe(data => {
      if (data) {
        this.getCurrentUserTeam(game, data.teamID);
      }
    });
  }

  async getCurrentUserTeam(game, team) {
    await this.db.collection('games').doc(game).collection('teams').doc(team).valueChanges().subscribe(
      (data: Team) => {
        this.currentTeam = data;
      });
  }

  getCurrentGame() {
    this.db.collection('games', ref => ref.where('status', '==', 'IN_PROGRESS')).valueChanges().subscribe(
      (data: Game[]) => {
        if (data[0] !== undefined) {
          this.currentGame = data[0].id;
          this.getTeams(this.currentGame);
          this.getCurrentUser(this.currentGame);
        }
      });
  }

  async getTeams(game) {
    await this.db.collection('games/' + game + '/teams').valueChanges().subscribe(
      (data: Team[]) => {
        this.teams = Util.sortByScore(data);
      });
    this.loading = false;
  }
}
